import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../../components/shared/Input';
import { InputPhone } from '../../../../components/shared/InputPhone';
import './index.scss';

export function ContactDetails({
	userInfo,
	setUserInfo,
	errors,
	validate,
	defaultCountry,
	getError,
}) {
	const { t } = useTranslation();

	const { firstName, lastName, phone, email, patronymic } = userInfo;

	const handleUpdateUserInfo = (fieldName, value) => {
		setUserInfo({ ...userInfo, [fieldName]: value });
	};

	return (
		<div className="contact-details">
			<Input
				name="lastName"
				value={lastName}
				updateValue={(value) => handleUpdateUserInfo('lastName', value)}
				label={t('checkout-page.fields.lastName')}
				type="text"
				validate={() => validate('lastName', lastName)}
				error={
					errors.includes('lastName')
						? getError(t('checkout-page.fields.lastName'))
						: ''
				}
				autocomplete="given-name"
			/>
			<Input
				name="firstName"
				value={firstName}
				updateValue={(value) => handleUpdateUserInfo('firstName', value)}
				label={t('checkout-page.fields.firstName')}
				type="text"
				validate={() => validate('firstName', firstName)}
				error={
					errors.includes('firstName')
						? getError(t('checkout-page.fields.firstName'))
						: ''
				}
			/>
			<Input
				name="patronymic"
				value={patronymic}
				updateValue={(value) => handleUpdateUserInfo('patronymic', value)}
				label={t('checkout-page.fields.patronymic')}
				type="text"
				autocomplete="additional-name"
			/>
			<InputPhone
				name="phone"
				value={phone}
				defaultCountry={defaultCountry}
				updateValue={(value) => handleUpdateUserInfo('phone', value)}
				label={t('checkout-page.fields.phone')}
				validate={(countryCode) => validate('phone', phone, countryCode)}
				error={errors.includes('phone') ? getError('phone') : ''}
				autocomplete="tel"
			/>
			<Input
				name="email"
				value={email}
				updateValue={(value) => handleUpdateUserInfo('email', value)}
				label={t('checkout-page.fields.email')}
				type="email"
				validate={() => validate('email', email)}
				error={errors.includes('email') ? getError('email') : ''}
				autocomplete="email"
			/>
		</div>
	);
}

ContactDetails.propTypes = {
	userInfo: PropTypes.instanceOf(Object).isRequired,
	setUserInfo: PropTypes.func.isRequired,
	getError: PropTypes.func.isRequired,
	validate: PropTypes.func.isRequired,
	defaultCountry: PropTypes.string.isRequired,
	errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};
