import { PropTypes } from 'prop-types';
import './index.scss';

export function Input({
	name,
	type,
	placeholder,
	value,
	validate,
	updateValue,
	label,
	error,
	autocomplete,
}) {
	const handleUpdateValue = (event) => {
		updateValue(event.target.value);
	};

	return (
		<div className="input-container">
			<label className="input-container__label" htmlFor={name}>
				{label}
			</label>
			<input
				className={`input-container__input ${
					error && 'input-container__input--error'
				}`}
				name={name}
				type={type}
				onBlur={validate}
				placeholder={placeholder}
				value={value}
				onChange={handleUpdateValue}
				autoComplete={autocomplete}
			/>
			<p className="input-container__error">{error}</p>
		</div>
	);
}

Input.defaultProps = {
	placeholder: '',
	label: '',
	error: '',
	validate: () => {},
	autocomplete: 'off',
};

Input.propTypes = {
	type: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	updateValue: PropTypes.func.isRequired,
	validate: PropTypes.func,
	label: PropTypes.string,
	error: PropTypes.string,
	name: PropTypes.string.isRequired,
	autocomplete: PropTypes.string,
};
