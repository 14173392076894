import { PropTypes } from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './index.scss';
import { useState } from 'react';

const availableCountries = ['ua', 'pl', 'kz'];

export function InputPhone({
	name,
	value,
	validate,
	updateValue,
	defaultCountry,
	label,
	error,
}) {
	const [country, setCountry] = useState(
		availableCountries.includes(defaultCountry) ? defaultCountry : 'ua',
	);

	const handleChange = (v, countryData) => {
		updateValue(v);
		setCountry(countryData.countryCode);
	};

	return (
		<div className="input-container">
			<label className="input-container__label" htmlFor={name}>
				{label}
			</label>
			<PhoneInput
				inputProps={{ name }}
				inputClass={`input-container__input ${
					error ? 'input-container__input--error' : ''
				}`}
				value={value}
				onChange={handleChange}
				onBlur={() => validate(country)}
				country={country}
				onlyCountries={availableCountries}
				placeholder=""
			/>
			<p className="input-container__error">{error}</p>
		</div>
	);
}

InputPhone.defaultProps = {
	label: '',
	error: '',
	defaultCountry: 'ua',
	validate: () => {},
};

InputPhone.propTypes = {
	value: PropTypes.string.isRequired,
	updateValue: PropTypes.func.isRequired,
	validate: PropTypes.func,
	label: PropTypes.string,
	error: PropTypes.string,
	defaultCountry: PropTypes.string,
	name: PropTypes.string.isRequired,
};
